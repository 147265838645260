import _ from 'lodash'
import React from 'react'
import { FramesManager } from '../view/frames-manager'
import { CustomFormulas, FormulaRegistry } from '../../helpers/formulas'
import { FormulaTask, IFormulaTaskProvider, IFormulaTaskResult } from './formula-batch-processor'

/**
 * Represents a legacy "formula UI component".
 */
export class FormulaComponentInfo {
  public id: string
  public valueRef: React.MutableRefObject<any>
  public frames: FramesManager
  public uiSchema: any
  public formula: string
  public valuePath: string[]
  public renderKey: string

  constructor(id, valueRef, frames) {
    this.id = id
    this.valueRef = valueRef
    this.frames = frames
    this.uiSchema = frames.getContext('uiSchema')
    if (_.has(this.uiSchema, 'value')) {
      this.valuePath = frames.getContext('valuePath')
    }
    // find formula, including those defined in the dataSchema
    const dataSchema = frames.getContext('dataSchema')
    this.formula = _.get(this.uiSchema, 'formula', _.get(dataSchema, 'formula'))
    this.renderKey = this.createRenderKey()
  }

  public setValueFromTask(result: IFormulaTaskResult): void {
    this.valueRef.current = result.value

    if (_.isObject(result.value) && result.value === result.task.originalValue) {
      // without a deep equality check, which might be risky here in terms of
      // either performance or safety (circular references), just force a
      // re-render in this case by creating a new render key for the underlying
      // input field. That way, the input field doesn't retain a stale display
      // value.
      this.renderKey = this.createRenderKey()
    }
  }

  private createRenderKey(): string {
    return _.uniqueId('formula-field-')
  }
}

export type FormulaComponentMap = {
  [id: string]: FormulaComponentInfo
}

/**
 * A collaborator for the entity renderer to register and unregister formula
 * fields to process in batch.
 *
 * This bridges existing formula UI components toward a more model-driven
 * approach.
 */
export class FormulaComponentRegistry implements IFormulaTaskProvider {
  private registrationChangeCallback: () => void
  private registry: FormulaComponentMap

  constructor(registrationChangeCallback: () => void) {
    this.registrationChangeCallback = registrationChangeCallback
    this.registry = {}
  }

  public register(componentInfo: FormulaComponentInfo): void {
    this.registry[componentInfo.id] = componentInfo
    this.registrationChangeCallback()
  }

  public unregister(componentID: string): void {
    _.unset(this.registry, componentID)
    this.registrationChangeCallback()
  }

  getRegisteredFields(): FormulaComponentMap {
    return this.registry
  }

  getFormulaTasks(): FormulaTask[] {
    const registeredFields = _.values(this.registry)
    return _.map(registeredFields, this.createTaskInfo)
  }

  private createTaskInfo(componentInfo: FormulaComponentInfo): FormulaTask {
    const { formula, valuePath, id, uiSchema, valueRef } = componentInfo
    const originalValue = valueRef.current
    const valueGetter = (key) => {
      const formula =
        FormulaRegistry.bindToLocalContext(key)?.bind({
          applicationContext: FormulaRegistry.getApplicationContext(),
          componentInfo: componentInfo,
        }) ?? CustomFormulas[key]
      const value = componentInfo.frames.getValue(key)
      return _.isNil(value) ? formula : value
    }
    return new FormulaTask({
      formula,
      valuePath,
      valueGetter,
      id,
      originalValue,
      schema: uiSchema,
    })
  }
}
