declare let window: any

import { Classes, Icon, Position, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Logger } from 'browser/apis/logging'
import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { CustomFormulas } from 'shared-libs/helpers/formulas'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { EntityFormPanel } from 'browser/components/atomic-elements/organisms/entity/entity-form-panel'
import { FilterCard } from 'browser/components/atomic-elements/organisms/filters/filter-card'
// tslint:disable-next-line:max-line-length
import { RenditionBillingConfirmationModal } from 'browser/components/atomic-elements/organisms/rendition-billing-confirmation-modal/rendition-billing-confirmation-modal'
import {
  IRenditionBillingViewHeaderOptions,
  RbViewHeaderInvoiceStatusSelector,
  RenditionBillingViewHeaderButtons,
} from 'browser/components/atomic-elements/organisms/view-header/rendition-billing-view-header/'
import { ViewHeader } from 'browser/components/atomic-elements/organisms/view-header/view-header'
import { Entity } from 'shared-libs/models/entity'
import {
  ISalesOrdersViewHeaderOptions,
  SalesOrdersViewHeaderButtons,
} from '../view-header/sales-order-view-header'
import { evaluateFilters } from 'shared-libs/helpers/evaluation'
import { SchemaUris } from 'shared-libs/models/schema'
import { filterTableColumns } from 'shared-libs/components/table/utils'
import { browserHistory } from 'browser/history'
import entitySelect from '../../atoms/select/entity-select'
import { LoadingSpinner } from '../../atoms/loading-spinner/loading-spinner'

const FEDEX_GROUND_FIRM_ID = '6fcc45a1-4710-470f-a46e-a86f439ac8c9'
const FEDEX_FIRM_ID = 'e274c433-993b-4829-a067-7fa332133684'

export interface IEntityPageContainerProps extends IBaseProps {
  addEntityButtonText?: string
  addEntitySchema?: string
  addEntityUiSchema?: string
  addEntityDefaultValue?: any
  addImportSchema?: string
  availableFilters: any
  blacklistedFilters: any
  filterProps: any
  baseViewId?: any
  dataSet: any
  entitySchema?: string | Entity
  hidePageSummary?: boolean
  handleToggleMap: any
  isDashboardsView?: any
  mapView: React.ReactElement<any>
  match: any
  onFiltersChange: (filters: any) => void
  onViewModeChange: (viewMode: IEntityPageContainerProps['viewMode']) => void
  onReviewAndPublishPressed?: any
  onReviewAndReImagingQueuedPressed?: any
  onReviewAndReImagingIndexedPressed?: any
  selection: any[]
  settings: Settings
  renditionBillingViewOptions?: IRenditionBillingViewHeaderOptions
  salesOrdersViewOptions?: ISalesOrdersViewHeaderOptions
  showAddEntityButton?: boolean

  showFilters?: boolean
  showExportButton?: boolean
  showImportButton?: boolean
  showHeader?: boolean
  showImagingButtons?: boolean
  showRenditionBillingButton?: boolean // Remove logic if/when RbV1 is deprecated
  showRenditionBillingStatusSelector?: boolean // Remove logic if/when RbV1 is deprecated
  showViewModeToggle?: boolean
  showPrintoutButton?: boolean
  totalItems?: any
  view: any
  viewMode?: 'list' | 'listDetail'
}

interface IEntityPageContainerState {
  addEntitySchema?: Entity
  entitySchema?: Entity
  isExporting: boolean
  showFilterCard: boolean
  showMap: boolean
  viewStateFilters: any[] // array inside view prop is mutated, so capture this for re-compute check
  evaluatedFilters?: any[]
  allowImport: boolean
  csvHeaders: any[]
  isLoading: boolean
}

export class EntityPageContainer extends React.Component<
  IEntityPageContainerProps,
  IEntityPageContainerState
> {
  public static defaultProps: Partial<IEntityPageContainerProps> = {
    showAddEntityButton: true,
    showImportButton: false,
    showExportButton: false,
    showFilters: true,
    showRenditionBillingButton: false,
    showRenditionBillingStatusSelector: false,
    showViewModeToggle: true,
  }

  constructor(props) {
    super(props)
    const { addEntitySchema, dataSet, entitySchema, settings, view } = props

    const addEntitySchemaPromise = _.isString(addEntitySchema) ? this.getEntitySchema(addEntitySchema).then(schema => {
      this.setState({
        addEntitySchema: schema,
      })
    }) : undefined

    const entitySchemaPromise = _.isNil(dataSet.entitySchema) ? this.getEntitySchema(entitySchema) : Promise.resolve(dataSet.entitySchema)
    entitySchemaPromise.then(schema => {
      this.setState({
        entitySchema: schema
      })
    })

    Promise.all([addEntitySchemaPromise, entitySchemaPromise]).then(_ => {
      this.setState({
        isLoading: false,
      })
    })

    this.state = {
      isLoading: true,
      isExporting: false,
      showFilterCard: false,
      showMap: false,
      viewStateFilters: view.view.state.filters,
      evaluatedFilters: evaluateFilters(view.view.state.filters, { settings, ...CustomFormulas }),
      allowImport: false,
      csvHeaders: [],
    }
  }

  public componentDidMount() {
    if (this.props.showImportButton) {
      const queryKey = Object.keys(this.props.view.view.state.queries)[0]
      const firm = this.props.view.owner.firm
      let entityType = this.props.view.view.state.queries[queryKey].entityType

      // if it's a document, hunt for the entitytype from the query or existing filters
      if (entityType === '/1.0/entities/metadata/document.json') {
        const queries = this.props.view.view.state.queries
        const filters = this.props.view.view.state.filters

        if (queries.documents.filters && queries.documents.filters.length > 0) {
          entityType = queries.documents.filters[0].entityType
        } else if (filters && filters.length > 0) {
          const filterWithEntityType = filters.find((filter) => !!filter.entityType)
          entityType = filterWithEntityType.entityType
        }
      }

      if (this.shouldShowImportButtonForEntity(firm, entityType)) {
        this.setState({ allowImport: true })

        const csvEntitySchema = apis.getStore().getRecord(entityType)
        apis.getCsvTemplateHeaders(csvEntitySchema.uniqueId).then((headers) => {
          this.setState({ csvHeaders: headers })
        })
      }
    }
  }

  public componentDidUpdate(prevProps, prevState, snapshot) {
    const { view, settings } = this.props
    const { viewStateFilters } = prevState
    const { filters } = view.view.state
    // `view.view.state.filters` mutates in both prev and next props, so
    // using state here to help catch when they actually change.
    if (!_.isEqual(viewStateFilters, filters)) {
      this.setState({
        viewStateFilters: filters,
        evaluatedFilters: evaluateFilters(filters, { settings, ...CustomFormulas }),
      })
    }
  }

  public render() {
    const { children, className } = this.props
    const { isLoading } = this.state

    if (isLoading) {
      return <LoadingSpinner />
    }

    return (
      <div className={classNames('grid-block vertical', className)}>
        {this.renderViewHeader()}
        <div className="grid-block">
          {this.renderFilterCard()}
          {children}
        </div>
      </div>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Renderers
  //////////////////////////////////////////////////////////////////////////////

  private renderViewHeader() {
    const {
      availableFilters,
      blacklistedFilters,
      baseViewId,
      dataSet,
      showFilters,
      isDashboardsView,
      hidePageSummary,
      onFiltersChange,
      showHeader,
      totalItems,
      view,
    } = this.props
    if (!showHeader) {
      return
    }
    const { entitySchema, evaluatedFilters } = this.state
    return (
      <ViewHeader
        availableFilters={availableFilters}
        blacklistedFilters={blacklistedFilters}
        baseViewId={baseViewId}
        filters={evaluatedFilters}
        entitySchema={entitySchema}
        hidePageSummary={hidePageSummary}
        showFilters={showFilters}
        onFiltersChange={onFiltersChange}
        orders={isDashboardsView ? null : dataSet.orders}
        toggleFilterCard={this.handleToggleFilterCard}
        totalItems={totalItems}
        view={view}
        dataSet={dataSet}
      >
        <div className="c-cardHeader-item--grow" />
        {this.renderRenditionBillingStatusSelector()}
        {this.renderViewModeToggle()}
        {this.renderMapToggle()}
        {this.renderImportButton()}
        {this.renderExportButton()}
        {this.renderPrintViewButton()}
        {this.renderRenditionBillingButton()}
        {this.renderRenditionBillingButtons()}
        {this.renderSalesOrdersButtons()}
        {this.renderAddEntityButton()}
        {this.renderImagingButtons()}
      </ViewHeader>
    )
  }

  private renderFilterCard() {
    const { availableFilters, blacklistedFilters, onFiltersChange, filterProps } = this.props
    const { entitySchema, showFilterCard, evaluatedFilters } = this.state
    return (
      <FilterCard
        availableFilters={availableFilters}
        blacklistedFilters={blacklistedFilters}
        filterProps={filterProps}
        entitySchema={entitySchema}
        filters={evaluatedFilters}
        isVisible={showFilterCard}
        onChange={onFiltersChange}
        onClose={this.handleToggleFilterCard}
      />
    )
  }

  private renderViewModeToggle() {
    const { showViewModeToggle, viewMode } = this.props
    if (showViewModeToggle) {
      return (
        <CardHeaderItem className="c-cardHeader-item--smallMargin">
          <div className={Classes.BUTTON_GROUP}>
            <Tooltip
              content="Use preview pane for selected item"
              position={Position.TOP}
              hoverOpenDelay={300}
            >
              <Button
                className={classNames({
                  [Classes.ACTIVE]: viewMode === 'listDetail',
                })}
                onClick={this.handleViewModeToListDetail}
              >
                <Icon icon="list-detail-view" size={14} />
              </Button>
            </Tooltip>
            <Tooltip
              content="Show full screen for selected item"
              position={Position.TOP}
              hoverOpenDelay={300}
            >
              <Button
                className={classNames({
                  [Classes.ACTIVE]: viewMode === 'list',
                })}
                onClick={this.handleViewModeToList}
              >
                <Icon icon="list" size={14} />
              </Button>
            </Tooltip>
          </div>
        </CardHeaderItem>
      )
    }
  }

  private renderMapToggle() {
    const { handleToggleMap, mapView } = this.props
    if (mapView) {
      return (
        <CardHeaderItem className="c-cardHeader-item--smallMargin">
          <Button onClick={handleToggleMap}>
            <Icon icon={IconNames.MAP_MARKER} />
          </Button>
        </CardHeaderItem>
      )
    }
  }

  private handleTogglePrintView = () => {
    browserHistory.push({
      pathname: this.props.match.url,
      search: "isPrintTable=true",
    })
  }

  private renderPrintViewButton() {
    const { showPrintoutButton } = this.props

    if (!showPrintoutButton) {
      return null
    }

    return (
      <CardHeaderItem className="c-cardHeader-item--smallMargin">
        <Button onClick={this.handleTogglePrintView}>
          Print View
        </Button>
      </CardHeaderItem>
    )
  }

  private renderExportButton() {
    const { showExportButton, showAddEntityButton, totalItems } = this.props
    const { isExporting } = this.state
    if (showExportButton) {
      const isExportButtonDisabled = totalItems > 7500
      return (
        <CardHeaderItem
          className={classNames({
            'c-cardHeader-item--noMargin': !showAddEntityButton,
            'c-cardHeader-item--smallMargin': showAddEntityButton,
          })}
        >
          <Tooltip
            disabled={!isExportButtonDisabled}
            content="Export limited to 7,500 items"
            position={Position.TOP}
          >
            <Button
              className="u-nowrap"
              onClick={this.handleExport}
              isLoading={isExporting}
              isDisabled={isExportButtonDisabled}
            >
              Export
            </Button>
          </Tooltip>
        </CardHeaderItem>
      )
    }
  }

  // RB V1
  private renderRenditionBillingStatusSelector = () => {
    const { selection, showRenditionBillingStatusSelector } = this.props
    if (showRenditionBillingStatusSelector) {
      return (
        <CardHeaderItem>
          <RbViewHeaderInvoiceStatusSelector selection={selection} />
        </CardHeaderItem>
      )
    }
  }

  // RB V1
  private renderRenditionBillingButton = () => {
    const { selection, showRenditionBillingButton, showAddEntityButton } = this.props
    if (showRenditionBillingButton) {
      return (
        <React.Fragment>
          <CardHeaderItem className="c-cardHeader-item--smallMargin">
            <Button
              className="u-nowrap"
              isDisabled={_.isEmpty(selection)}
              onClick={this.handleRenditionBillingPressed}
            >
              Bill Selected
            </Button>
          </CardHeaderItem>
          <CardHeaderItem
            className={classNames({
              'c-cardHeader-item--noMargin': !showAddEntityButton,
              'c-cardHeader-item--smallMargin': showAddEntityButton,
            })}
          >
            <Button
              className="u-nowrap"
              isDisabled={!_.isEmpty(selection)}
              onClick={this.handleRenditionBillingPressed}
            >
              Bill All
            </Button>
          </CardHeaderItem>
        </React.Fragment>
      )
    }
  }

  // RB V2
  private renderRenditionBillingButtons = () => {
    const { dataSet, onViewModeChange, renditionBillingViewOptions, selection } = this.props
    return (
      <>
        {renditionBillingViewOptions && (
          <RenditionBillingViewHeaderButtons
            dataSet={dataSet}
            handleExport={this.handleExport}
            handleShowEntityFormPanel={this.handleShowEntityFormPanel}
            onViewModeChange={onViewModeChange}
            selection={selection}
            {...renditionBillingViewOptions}
          />
        )}
      </>
    )
  }

  private renderSalesOrdersButtons = () => {
    const { dataSet, onViewModeChange, salesOrdersViewOptions, selection } = this.props
    return (
      <>
        {salesOrdersViewOptions && (
          <SalesOrdersViewHeaderButtons
            dataSet={dataSet}
            handleExport={this.handleExport}
            handleShowEntityFormPanel={this.handleShowEntityFormPanel}
            onViewModeChange={onViewModeChange}
            selection={selection}
            {...salesOrdersViewOptions}
          />
        )}
      </>
    )
  }

  // RB V1
  private handleRenditionBillingPressed = () => {
    const { dataSet, selection } = this.props
    RenditionBillingConfirmationModal.open({ dataSet, selection })
  }

  private handleViewModeToListDetail = () => {
    const { onViewModeChange } = this.props
    onViewModeChange('listDetail')
  }

  private handleViewModeToList = () => {
    const { onViewModeChange } = this.props
    onViewModeChange('list')
  }

  private handleExport = () => {
    const { dataSet, view, settings } = this.props
    const columns = filterTableColumns(view.get('view.state.columns'), settings)
    this.setState({ isExporting: true })
    Toast.show({
      message: `Generating ${view.displayName} export.`,
      position: Position.BOTTOM_RIGHT,
      timeout: 5000,
    })
    const metadata = dataSet.query.metadata
    const query = {
      columns,
      computations: dataSet.query.computations,
      entityType: dataSet.entitySchema.uniqueId,
      filters: dataSet.query.filters,
      groups: dataSet.query.groups,
      metadata: {
        shouldIncludeLeafEntities: metadata.shouldIncludeLeafEntities,
      },
      orders: dataSet.query.orders,
      timezone: settings.getTimezone(),
    }
    apis
      .exportCsv(query)
      .then((response: string) => {
        // https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
        const isIE11 = !!window.MSInputMethodContext && !!window.document.documentMode
        if (isIE11) {
          // https://stackoverflow.com/questions/47881250/typeerror-object-doesnt-support-this-action-in-ie-11
          const blob = new Blob([response], { type: 'text/csv; charset=utf-8' })
          window.navigator.msSaveBlob(blob, 'export.csv')
        } else {
          const blob = new File([response], 'export.csv', {
            type: 'text/csv; charset=utf-8',
          })
          const URL = window.URL || window.webkitURL
          const downloadUrl = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = downloadUrl
          a.download = 'export.csv'
          document.body.appendChild(a)
          a.click()
        }
      })
      .finally(() => {
        this.setState({ isExporting: false })
      })
  }

  private renderAddEntityButton() {
    const { addEntityButtonText, showAddEntityButton } = this.props
    const { entitySchema } = this.state
    if (showAddEntityButton) {
      const schemaTitle = entitySchema.get('title')
      const buttonText = addEntityButtonText ? addEntityButtonText : `Add ${schemaTitle}`
      return (
        <CardHeaderItem className="c-cardHeader-item--noMargin">
          <Button
            className={classNames('u-nowrap', Classes.INTENT_PRIMARY)}
            onClick={this.handleShowEntityFormPanel}
            data-debug-id="newEntityButton"
          >
            {buttonText}
          </Button>
        </CardHeaderItem>
      )
    }
  }

  private renderImagingButtons() {
    const {
      dataSet,
      onReviewAndPublishPressed,
      onReviewAndReImagingQueuedPressed,
      onReviewAndReImagingIndexedPressed,
      showImagingButtons,
    } = this.props
    // Assumption here is that we would only use this when we are dealing with documentClassificationTask
    const { entities } = dataSet
    const { id } = dataSet.entitySchema
    if (
      id === '/1.0/entities/metadata/documentClassificationTask.json' &&
      showImagingButtons &&
      entities
    ) {
      const indexableTasksExist = _.some(entities, (entity) => {
        const { taskStatus } = entity.documentClassificationTask
        const status = _.get(entity, 'status.state')
        return taskStatus === 'Indexed' && status === 'idle'
      })
      const queueableTasksExist = _.some(entities, (entity) => {
        const { taskStatus } = entity.documentClassificationTask
        const status = _.get(entity, 'status.state')
        return taskStatus === 'Queued' && status === 'idle'
      })
      return (
        <React.Fragment>
          <CardHeaderItem className="c-cardHeader-item--smallMargin">
            <Button buttonText={'Add Files'} onClick={this.handleShowEntityFormPanel} />
          </CardHeaderItem>
          <CardHeaderItem className="c-cardHeader-item--smallMargin">
            <Button
              buttonText={'Publish Indexed'}
              isDisabled={!indexableTasksExist}
              onClick={onReviewAndPublishPressed}
            />
          </CardHeaderItem>
          <CardHeaderItem className="c-cardHeader-item--noMargin">
            <Button
              buttonText={'ReImage'}
              isDisabled={!queueableTasksExist}
              onClick={onReviewAndReImagingQueuedPressed}
            />
          </CardHeaderItem>
        </React.Fragment>
      )
    }
  }

  private renderImportButton() {
    const { showImportButton } = this.props
    const { allowImport, csvHeaders } = this.state
    if (allowImport && showImportButton) {
      return (
        <CardHeaderItem className="c-cardHeader-item--smallMargin">
          <Button
            buttonText={'Import'}
            isLoading={csvHeaders.length < 1}
            onClick={this.handleShowImportPanel}
          />
        </CardHeaderItem>
      )
    }
    return null
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleToggleFilterCard = () => {
    if (!this.state.showFilterCard) {
      Logger.logEvent('Show Filter Panel')
    }
    this.setState({ showFilterCard: !this.state.showFilterCard })
  }

  private handleShowEntityFormPanel = () => {
    const { addEntityUiSchema, match, settings, addEntityDefaultValue } = this.props
    const { addEntitySchema, entitySchema } = this.state

    EntityFormPanel.openAndResolveDependencies({
      defaultValue: addEntityDefaultValue,
      redirectPathPrefix: match.url,
      entityId: (addEntitySchema || entitySchema)?.uniqueId,
      settings,
      uiSchemaPath: addEntityUiSchema,
    })
  }

  private handleShowImportPanel = async () => {
    const { settings } = this.props
    const { csvHeaders } = this.state
    const schema = await this.getEntitySchema('/1.0/entities/metadata/csvImport.json')

    const contextProps = {
      density: 'collapse',
      isHorizontalLayout: true,
      headers: csvHeaders,
    }
    EntityFormPanel.openAndResolveDependencies({
      entityId: schema.uniqueId,
      settings,
      uiContext: contextProps,
      uiSchemaPath: 'uiSchema.web.entityCreationPanel',
    })
  }

  private async getEntitySchema(entitySchema) {
    if (!entitySchema) {
      return
    }

    let mappedEntity = entitySchema

    if (_.isString(entitySchema)) {
      mappedEntity = apis.getStore().getRecord(entitySchema)

      if (_.isEmpty(mappedEntity)) {
        mappedEntity = await apis.getStore().findSchema(entitySchema)
      }
    }
    return (mappedEntity instanceof Entity) ? mappedEntity : new Entity(mappedEntity, apis)
  }

  private shouldShowImportButtonForEntity(firm, entityType: string) {
    const { settings } = this.props
    return settings.isAdmin
      || firm.entityId === FEDEX_GROUND_FIRM_ID
      || entityType === SchemaUris.USER_INVITE
      || (entityType === SchemaUris.USER && settings.isFirmAdmin)
      || (entityType === SchemaUris.FEDEX_FACILITY && settings.isFirmAdmin && FEDEX_FIRM_ID === firm.entityId)
      || (entityType === SchemaUris.LOCATION && settings.isAdmin)
  }
}
