import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import 'browser/components/atomic-elements/atoms/section/section-header/_section-header.scss'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'

interface ISectionHeaderProps extends IBaseProps {
  isCollapsable?: boolean
  isCollapsed?: boolean

  onIsCollapsedChange?: (value: boolean) => void
  onHideEmptyFieldsChange?: (value: boolean) => void
  showHideEmptyFieldsControls?: boolean
  hideEmptyFields?: boolean
  hideHeaderBorder?: boolean
  title?: string
  titleElement?: React.ReactElement<any>
  style?: React.CSSProperties
  subtitle?: string
  headerControls?: React.ReactElement<any>
  optionsPopoverList?: React.ReactElement<any>
  optionsPopoverTetherOptions?: object
}

export class SectionHeader extends React.Component<ISectionHeaderProps, any> {
  public static defaultProps: ISectionHeaderProps = {
    hideHeaderBorder: true,
    optionsPopoverTetherOptions: {
      attachment: 'top right',
      targetAttachment: 'bottom right',
    },
  }

  constructor(props) {
    super(props)
  }

  public render() {
    const { className, hideHeaderBorder, isCollapsed, title, titleElement, subtitle, style } = this.props
    if (_.isEmpty(titleElement) && _.isEmpty(title) && _.isEmpty(subtitle)) {
      return null
    }
    return (
      <div
        style={style}
        className={classNames('c-sectionHeader', className, {
          'c-sectionHeader--noBorderBottom': hideHeaderBorder && !isCollapsed,
        })}
      >
        {this.renderTitle()}
        {this.renderHeaderControls()}
      </div>
    )
  }

  private renderTitle() {
    const { title, titleElement, subtitle } = this.props
    if (!_.isEmpty(titleElement)) {
      return titleElement
    }
    if (!_.isEmpty(subtitle)) {
      return (
        <div className='c-sectionHeaderGroup'>
          <Label>
            {subtitle}
          </Label>
        </div>
      )
    }
    return (
      <div className='c-sectionHeaderGroup'>
        <div className='c-sectionHeader-title-nowrap'>
          {title}
        </div>
      </div>
    )
  }

  private renderHeaderControls() {
    const {
      headerControls,
    } = this.props

    return (
      <div className='c-sectionHeaderGroup'>
        {headerControls}
        {this.renderCollapseControls()}
        {this.renderHiddenFieldsVisibilityToggle()}
        {this.renderOptionsPopoverList()}
      </div>
    )
  }

  private renderOptionsPopoverList() {
    const {
      optionsPopoverList,
      optionsPopoverTetherOptions,
    } = this.props

    if (optionsPopoverList) {
      return (
        <TetherTarget
          tetherOptions={optionsPopoverTetherOptions}
          tethered={this.renderOptionsPopover()}
        >
          <div className={classNames('u-bumperLeft', Classes.BUTTON, Classes.MINIMAL)}>
            Options
          </div>
        </TetherTarget>
      )
    }
  }

  private renderOptionsPopover() {
    const { optionsPopoverList } = this.props
    return (
      <Popover
        className='collapse'
      >
        {optionsPopoverList}
      </Popover>
    )
  }

  private renderHiddenFieldsVisibilityToggle() {
    const { showHideEmptyFieldsControls, hideEmptyFields } = this.props
    if (showHideEmptyFieldsControls) {
      const buttonText = hideEmptyFields ? 'Show All Fields' : 'Hide Empty Fields'
      return (
        <Button
          className={classNames('u-bumperLeft', Classes.MINIMAL)}
          size='small'
          onClick={this.handleHideEmptyFieldsChange}
        >
          {buttonText}
        </Button>
      )
    }
  }

  private renderCollapseControls() {
    const { isCollapsable, isCollapsed } = this.props
    if (isCollapsable) {
      const buttonText = isCollapsed ? 'Expand' : 'Collapse'
      return (
        <Button
          className={classNames('u-bumperLeft', Classes.MINIMAL)}
          size='small'
          onClick={this.handleIsCollapsedChange}
        >
          {buttonText}
        </Button>
      )
    }
  }

  private handleIsCollapsedChange = () => {
    const { onIsCollapsedChange, isCollapsed } = this.props
    onIsCollapsedChange(!isCollapsed)
  }

  private handleHideEmptyFieldsChange = () => {
    const { onHideEmptyFieldsChange, hideEmptyFields } = this.props
    onHideEmptyFieldsChange(!hideEmptyFields)
  }
}
