import _ from 'lodash'
import React from 'react'
import {
  Tab,
  Icon,
  Tabs,
  Button,
  Classes,
  Popover,
  Position,
  ButtonGroup,
  PopoverInteractionKind,
} from '@blueprintjs/core'
import classNames from 'classnames'
import queryString from 'query-string'
import styled from 'styled-components'
import SplitPane from 'react-split-pane'
import { linter } from '@codemirror/lint'
import apis from 'browser/app/models/apis'
import { ViewUpdate } from '@codemirror/view'
import { Transaction } from '@codemirror/state'
import { browserHistory } from 'browser/history'
import { Entity } from 'shared-libs/models/entity'
import { TryAsync } from 'shared-libs/helpers/utils'
import { ComponentInfo, PropertyInfo } from './types'
import { Toast } from 'browser/mobile/components/toast/toast'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { SafeView } from 'shared-libs/components/view/safe-view'
import { formatJsonString } from 'shared-libs/helpers/formatter'
import { ChevronRight, Cross, IconNames } from '@blueprintjs/icons'
import { ConnectionConfig, SandboxRenderer } from './sandbox-renderer-view'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { copyShareableToolsURL, getShareableToolsURL, unpackToolsURL } from '../utils'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { CodeMirrorInput, formatButtonPanelExts } from 'browser/components/atomic-elements/atoms/input/code-mirror-input'

import 'browser/app/pages/app/tools/_tools.scss'
import './_component-sandbox.scss'

declare let __GITHASH__: string
declare let __PROXY_HOST__: string

// TODO: side note: add `updateLocation` to other sandboxes

// TODO: should there be buttons for inserting a component into the editor at the cursor location?

type PlatformType = 'web' | 'mobileWeb' | 'mobile'

const platformToLabel: Record<PlatformType, string> = {
  web: 'Desktop Web',
  mobileWeb: 'Mobile Web',
  mobile: 'Mobile',
}

type EditorTabId = 'schema' | 'entity'
type EditorDisplayMode = 'tabbed' | 'vsplit' | 'hsplit'

const editorModeToLabel: { [key in EditorDisplayMode]: string } = {
  tabbed: 'Tabs',
  hsplit: 'H-Split',
  vsplit: 'V-Split',
}

interface IRenderingToolProps extends IBaseProps {
  frames?: any
}

interface IRenderingToolState {
  componentMetadata?: Record<string, Record<string, ComponentInfo>>
  /**
   * Initializes the editors, separate from schemaString/entityString which are
   * only our copies of the current editor state (needs to not be fed back into
   * CodeMirror's `value`).
   */
  initialSchemaString?: string
  initialEntityString?: string
  schemaString?: string
  entityString?: string
  schema?: Entity
  entity?: Entity
  errors?: any[]
  isLoading?: boolean
  selectedPlatform?: PlatformType
  selectedComponent?: string
  selectedEditorDisplayMode?: EditorDisplayMode
  selectedEditorTab?: EditorTabId
}

const PLACEHOLDER_SCHEMA = `{
  "$schema": "http://json-schema.org/draft-04/schema#",
  "properties": {
    "renderingSandbox": {
      "properties": {
        "str": {
          "type": "string"
        }
      },
      "type": "object"
    }
  },
  "uiSchema": {
    "type": "ui:view",
    "children": [
      {
        "label": "Test",
        "type": "ui:inputField",
        "isDisabled": true,
        "value": "renderingSandbox.str"
      }
    ]
  },
  "id": "/1.0/renderingSandbox.json",
  "uniqueId": "05e2af20-b0a9-4886-9aaa-1e25bf59d0d2",
  "allOf": [
    {
      "$ref": "/1.0/entities/metadata/entity.json"
    }
  ],
  "metadata": {
    "namespace": "renderingSandbox"
  },
  "mixins": {
    "active": [
      {
        "displayName": "Entity",
        "entityId": "11111111-0000-0000-0000-000000000000"
      },
      {
        "displayName": "Entity Schema",
        "entityId": "11111111-0000-0000-0000-000000000001"
      }
    ],
    "inactive": []
  },
  "type": "object"
}`

const PLACEHOLDER_ENTITY_JSON = `{
  "renderingSandbox": {
    "str": "test"
  },
  "allOf": [
    {
      "$ref": "/1.0/renderingSandbox.json"
    }
  ],
  "mixins": {
    "active": [
      {
        "entityId": "05e2af20-b0a9-4886-9aaa-1e25bf59d0d2"
      }
    ]
  }
}`

export class RenderingTool extends React.Component<IRenderingToolProps, IRenderingToolState> {
  private entityEditorRef = React.createRef<CodeMirrorInput>()
  private sandboxRendererRef = React.createRef<SandboxRenderer>()

  constructor(props: IRenderingToolProps) {
    super(props)

    this.state = {
      isLoading: true,
      selectedEditorDisplayMode: 'tabbed',
    }
  }

  public componentDidMount() {
    void this.initialize()
  }

  private initialize = async () => {
    const {
      selectedPlatform = 'web',
      selectedComponent,
      selectedEditorDisplayMode,
      schemaString: initialSchemaString = PLACEHOLDER_SCHEMA,
      entityString: initialEntityString = PLACEHOLDER_ENTITY_JSON,
    } = unpackToolsURL() as Partial<IRenderingToolState>

    const entityState = { initialEntityString, entityString: initialEntityString }
    const schemaState = { initialSchemaString, schemaString: initialSchemaString }

    const componentMetadataUrl = `${apis.WEBAPP_URL}/componentMetadata-${__GITHASH__}.json`
    const componentMetadata = await TryAsync(async () => (await fetch(componentMetadataUrl)).json())
    if (!componentMetadata) {
      console.error('Error: Failed to fetch component metadata, do you need to run the json codegen?')
    }

    this.setState(
      {
        ...entityState,
        ...schemaState,
        selectedPlatform,
        selectedComponent,
        selectedEditorDisplayMode: selectedEditorDisplayMode || 'tabbed',
        componentMetadata,
        isLoading: false,
      },
      this.evaluate
    )
  }

  public render() {
    return <div className="grid-block vertical">{this.renderCard()}</div>
  }

  private renderCard() {
    const { isLoading } = this.state

    if (isLoading) {
      return <LoadingSpinner />
    }

    return (
      <div className="flex flex-column h-100">
        {this.renderPlatformSelector()}
        <div className="flex flex-row flex-grow overflow-hidden">
          {this.renderComponentSelector()}
          <SplitPane
            style={{ position: 'static' }}
            pane2Style={{ borderTop: '1px solid #edeeee' }}
            split="vertical"
            minSize={'50%'}
            defaultSize={'50%'}
          >
            {this.renderEditorPane()}
            {this.renderRendererPane()}
          </SplitPane>
        </div>
      </div>
    )
  }

  private handleSelectPlatform = (platform: PlatformType) => {
    this.setState({ selectedPlatform: platform, selectedComponent: undefined }, this.updateLocation)
  }

  private renderPlatformSelector() {
    const { selectedPlatform } = this.state
    return (
      <Tabs
        className="u-borderBottom"
        selectedTabId={selectedPlatform}
        onChange={this.handleSelectPlatform}
      >
        {_.map(['web', 'mobileWeb', 'mobile'], (platform) => (
          <Tab id={platform} title={platformToLabel[platform]} key={platform} />
        ))}
      </Tabs>
    )
  }

  private renderComponentSelector() {
    const { selectedPlatform } = this.state
    if (!selectedPlatform) {
      return null
    }
    return (
      <div className="flex flex-row pt2">
        {this.renderComponentList()}
        {this.renderComponentInfo()}
      </div>
    )
  }

  private renderComponentList() {
    const { componentMetadata, selectedPlatform } = this.state

    if (!componentMetadata) {
      return (
        <div className="mw5 pa3">
          Component metadata failed to load. Are you running this locally and need to run the json
          codegen?
        </div>
      )
    }

    const components = _.entries(componentMetadata[selectedPlatform]).sort(([keyA], [keyB]) =>
      keyA.localeCompare(keyB)
    )

    return (
      <div className="c-sandboxComponentList pa2 h-100 overflow-y-auto ">
        <div className="c-sandboxListHeader flex pa1 u-borderBottom">
          <h5 className="content-center flex-grow">Components</h5>
        </div>
        {_.map(components, ([name, componentInfo]) => {
          const { comment, properties } = componentInfo
          return (
            <div
              className={classNames('flex flex-row items-center', {
                'bg-light-gray': name === this.state.selectedComponent,
              })}
              key={name}
              onClick={() => this.handleSelectComponent(componentInfo)}
            >
              <span className="u-pointerNone">{name}</span>
              <span className="flex-grow">{this.renderDocPopover(comment)}</span>
              {!_.isEmpty(properties) && <ChevronRight />}
            </div>
          )
        })}
      </div>
    )
  }

  private handleSelectComponent = (info: ComponentInfo) => {
    const { selectedComponent } = this.state
    const { identifier, properties } = info
    if (_.isEmpty(properties)) {
      return
    }
    this.setState(
      {
        selectedComponent: selectedComponent === identifier ? undefined : identifier,
      },
      this.updateLocation
    )
  }

  private renderDocPopover(text: string | undefined) {
    if (!text) {
      return null
    }
    return (
      <Popover
        content={<div className="pre-wrap pa3 mw7">{text}</div>}
        interactionKind={PopoverInteractionKind.HOVER}
        minimal={true}
        position={Position.RIGHT}
      >
        <Icon icon={IconNames.SMALL_INFO_SIGN} className={'ml1'} />
      </Popover>
    )
  }

  private renderComponentInfo() {
    const { componentMetadata, selectedPlatform, selectedComponent } = this.state

    const componentInfo: ComponentInfo = componentMetadata?.[selectedPlatform]?.[selectedComponent]
    if (!componentInfo) {
      return null
    }

    // TODO: move this up to `handleSelectComponent` be a one-time operation
    // outside of render.
    const groupEntries = _.chain(componentInfo.properties)
      .entries()
      .sort(this.compareProperties)
      .groupBy(([_, property]) => property.parentName)
      .value()

    return (
      <div className="pa2 mw6 u-borderRight flex flex-column">
        <div className="c-sandboxListHeader flex flex-row u-borderBottom pa1">
          <h5 className="content-center flex-grow">{componentInfo.identifier}</h5>
          <Button className="ml2" onClick={() => this.handleSelectComponent(componentInfo)}>
            <Cross />
          </Button>
        </div>
        {this.renderPropertyGroups(groupEntries)}
      </div>
    )
  }

  private compareProperties(
    [nameA, propA]: [string, PropertyInfo],
    [nameB, propB]: [string, PropertyInfo]
  ): number {
    const hasParentLinkA = !!propA.parentLink
    const hasParentLinkB = !!propB.parentLink

    if (hasParentLinkA !== hasParentLinkB) {
      // by provenance
      return hasParentLinkA ? -1 : 1
    } else if (propA.parentName !== propB.parentName) {
      // by parent name
      return propA.parentName.localeCompare(propB.parentName)
    } else if (propA.optional !== propB.optional) {
      // by requiredness
      return !propA.optional ? -1 : 1
    } else {
      // by property name
      return nameA.localeCompare(nameB)
    }
  }

  private renderPropertyGroups(
    groups: Record<string, [string, PropertyInfo][]>
  ): JSX.Element | null {
    if (_.isEmpty(groups)) {
      return null
    }
    return (
      <div className="flex flex-column overflow-y-auto">
        {_.map(groups, (properties, parentName) => {
          return this.renderPropertyGroup(parentName, properties)
        })}
      </div>
    )
  }

  private renderPropertyGroup(
    parentName: string,
    properties: [string, PropertyInfo][]
  ): JSX.Element {
    const parentLink = _.find(properties, ([, prop]) => prop.parentLink)?.[1].parentLink

    // render github link to our interfaces
    const groupNameElement = parentLink ? (
      <a href={parentLink} target="_blank" rel="noopener noreferrer">
        {parentName}
      </a>
    ) : (
      parentName
    )

    return (
      <div className="c-prop-group" key={parentName}>
        <div className="c-h6 mb1 mt3">
          {groupNameElement}
        </div>
        <div>
          {_.map(properties, ([_, propertyInfo]) => {
            return this.renderProperty(propertyInfo)
          })}
        </div>
      </div>
    )
  }

  private renderProperty(propertyInfo: PropertyInfo) {
    const { name, optional } = propertyInfo

    return (
      <div key={name} className="u-bumperLeft">
        <span className="u-pointerNone">
          {name}
          {optional ? '?' : ''}:{' '}
        </span>
        {this.renderPropertyType(propertyInfo)}
        {this.renderDocPopover(propertyInfo.description)}
      </div>
    )
  }

  private renderPropertyType(propertyInfo: PropertyInfo) {
    const { typeName, extendedJsonTypeName } = propertyInfo

    if (extendedJsonTypeName === 'union' && _.size(typeName) > 25) {
      return <ExpandableType typeName={typeName} />
    }
    return <strong className="u-pointerNone">{typeName || ''}</strong>
  }

  private renderRendererPane() {
    return (
      <div className="w-100 ba br2 bw4 b--orange overflow-hidden">
        {this.renderErrors()}
        {this.renderRenderer()}
      </div>
    )
  }

  private renderEditorPane() {
    const { selectedEditorDisplayMode } = this.state

    return (
      <div className="relative w-100 h-100 pt2">
        <div className="absolute top-0 right-0 pa2">
          <ButtonGroup>
            {_.map(editorModeToLabel, (label, mode: EditorDisplayMode) => (
              <Button
                key={label}
                onClick={() => this.handleEditorDisplayModeChange(mode)}
                active={selectedEditorDisplayMode === mode}
              >
                {label}
              </Button>
            ))}
          </ButtonGroup>
        </div>
        {this.renderTabbedEditor()}
        {this.renderSplitEditor()}
      </div>
    )
  }

  private handleEditorDisplayModeChange = (mode: EditorDisplayMode) => {
    // also update the initial editor states to the latest contents, since the
    // editor will be re-created when switching modes, and the old initial state
    // will now be stale.
    const { entityString, schemaString } = this.state
    return this.setState(
      {
        selectedEditorDisplayMode: mode,
        initialEntityString: entityString,
        initialSchemaString: schemaString,
      },
      this.updateLocation
    )
  }

  private renderTabbedEditor() {
    const { selectedEditorTab, selectedEditorDisplayMode } = this.state
    if (selectedEditorDisplayMode !== 'tabbed') {
      return
    }
    return (
      <Tabs
        className="u-borderBottom w-100 h-100 flex flex-column pt3"
        selectedTabId={selectedEditorTab}
        onChange={(selectedEditor: EditorTabId) =>
          this.setState({ selectedEditorTab: selectedEditor })
        }
      >
        <Tab
          id="schema"
          title="Schema"
          panel={this.renderEditor('schema')}
          panelClassName="w-100 flex-grow overflow-y-auto"
        />
        <Tab
          id="entity"
          title="Entity"
          panel={this.renderEditor('entity')}
          panelClassName="w-100 flex-grow overflow-y-auto"
        />
      </Tabs>
    )
  }

  private renderSplitEditor() {
    const { selectedEditorDisplayMode } = this.state
    if (selectedEditorDisplayMode !== 'vsplit' && selectedEditorDisplayMode !== 'hsplit') {
      return null
    }

    const splitMode = selectedEditorDisplayMode === 'hsplit' ? 'horizontal' : 'vertical'

    return (
      <SplitPane
        key={splitMode}
        minSize={'45%'}
        split={splitMode}
        defaultSize={'45%'}
        className="pt3 static"
        style={{ position: 'static' }}
        paneStyle={{ overflow: 'hidden' }}
        pane2Style={{ borderLeft: '1px solid #edeeee' }}
      >
        <Section title="Schema" className="w-100" bodyClassName="h-100">
          {this.renderEditor('schema')}
        </Section>
        <Section title="Entity" className="w-100" bodyClassName="h-100">
          {this.renderEditor('entity')}
        </Section>
      </SplitPane>
    )
  }

  private renderEditor(editor: EditorTabId) {
    const { initialEntityString, initialSchemaString } = this.state
    const editorProps = {
      ref: editor === 'schema' ? undefined : this.entityEditorRef,
      value: editor === 'schema' ? initialSchemaString : initialEntityString,
      onChange: (value, update: ViewUpdate) => {
        const isLocal = !update.transactions[0].annotation(Transaction.remote)
        const isFromEditor = !!update.transactions[0].annotation(Transaction.userEvent)
        this.setState({ [`${editor}String`]: value }, _.partial(this.evaluate, isLocal, isFromEditor))
      },
    }
    return (
      <CodeMirrorInput
        {...editorProps}
        extensions={[json(), linter(jsonParseLinter()), ...formatButtonPanelExts(formatJsonString)]}
      />
    )
  }

  private renderRenderer() {
    const { selectedPlatform, entity, schema, entityString, schemaString } = this.state

    if (!entity || !schema) {
      return null
    }

    if (!selectedPlatform) {
      return <div className="pa2">Please select a platform</div>
    }

    return (
      <div className="flex flex-column flex-grow h-100">
        <SafeView
          errorInvalidationKey={{ selectedPlatform, entityString, schemaString, schema, entity }}
          shouldInvalidateError={(
            { errorInvalidationKey: keyA },
            { errorInvalidationKey: keyB }
          ) => {
            return (
              keyA.selectedPlatform !== keyB.selectedPlatform ||
              keyA.entityString !== keyB.entityString ||
              keyA.schemaString !== keyB.schemaString ||
              keyA.schema !== keyB.schema ||
              keyA.entity !== keyB.entity
            )
          }}
          renderError={(error) => <div className="pre-wrap">Error: {error.message}</div>}
        >
          <SandboxRenderer
            ref={this.sandboxRendererRef}
            entity={entity}
            onChange={this.handleEntityChange}
            onChangeContent={this.handleEntityContentChange}
            onCopyMobileDeeplinkClick={this.handleCopyMobileDeeplink}
            onConfigUpdate={this.handleConfigUpdate}
            schema={schema}
            selectedPlatform={selectedPlatform}
          />
        </SafeView>
      </div>
    )
  }

  private renderErrors() {
    const { errors } = this.state
    if (_.isEmpty(errors)) {
      return null
    }
    return (
      <div className="col-xs-12 pa1">
        {errors.map((error, idx) => this.renderError(error, idx))}
      </div>
    )
  }

  private renderError(error, idx) {
    return (
      <ErrorMessageContainer key={idx}>
        <ErrorTitle>
          {`${error.type} error:`}
        </ErrorTitle>
        <ErrorText>
          {error.message}
        </ErrorText>
      </ErrorMessageContainer>
    )
  }

  /* internal updates from a web EntityRenderer locally */
  private handleEntityChange = (entity: Entity) => {
    const entityString = JSON.stringify(entity.content, null, 2)
    this.entityEditorRef.current?.setText(entityString)
  }

  /* remote updates coming via the sandbox server websocket, triggered by a connected client */
  private handleEntityContentChange = (json: any) => {
    const entityString = JSON.stringify(json, null, 2)
    this.entityEditorRef.current?.setText(entityString, { isRemote: true })
  }

  private handleCopyMobileDeeplink = () => {
    const { entityString, schemaString } = this.state
    const payload = { entityString, schemaString }
    const shareableUrl = getShareableToolsURL(payload)
    
    // get the correct remote host, even if running in local webapp dev-server,
    // which uses proxy host instead of api host.
    const host = __PROXY_HOST__ || apis.getAPIHost()
    const appHost = host.replace('api', 'app')
    const url = new URL('/sandbox', appHost)
    url.search = shareableUrl.search

    // if we couldn't obtain a proper remote webapp host, bail out, for now,
    // since mobile cannot handle localhost deeplinks.
    if (url.hostname.includes('localhost')) {
      Toast.show({
        message: 'Cannot copy localhost link (unsupported)',
        position: 'bottom-right',
        timeout: 5000,
      })
    } else {
      copyShareableToolsURL(url.toString(), 'Mobile deeplink copied to clipboard')
    }
  }

  private handleConfigUpdate = (config: ConnectionConfig) => {
    this.updateLocation({ ...config })
  }

  /**
   * Parses the editor content, re-constructs the Entities and broadcasts to
   * connected clients if needed.
   *
   * @param isLocal whether the change originated on this machine
   * @param isFromEditor whether the change originated from the Codemirror
   * editor (as opposed to renderer). If the renderer.
   */
  private evaluate = _.debounce((isLocal: boolean = true, isFromEditor: boolean = true) => {
    const { schemaString, entityString } = this.state
    let { entity, schema } = this.state

    if (!isLocal && isFromEditor) {
      throw new Error("Invalid state: isLocal=false and isFromEditor=true")
    }

    const errors = []

    const shouldReconstructEntities =  !isLocal || isFromEditor

    try {
      const schemaJson = JSON.parse(schemaString)
      if (shouldReconstructEntities) {
        schema = new Entity(schemaJson, apis)
        apis.getStore().cacheRecord(schema)
      }
    } catch (e) {
      errors.push({
        type: 'schema',
        message: e.message || e,
      })
    }

    try {
      const json = JSON.parse(entityString)
      if (shouldReconstructEntities) {
        entity = new Entity(json, apis)
      }
    } catch (e) {
      errors.push({
        type: 'entity',
        message: e.message || e,
      })
    }

    this.setState({ entity, schema, errors }, this.updateLocation)

    // broadcast only if it originated on this machine / socket client
    if (isLocal) {
      this.sandboxRendererRef.current?.sendLatestValue(entity.content, schema.content)
    }
  }, 400)

  private updateLocation = (additionalParams?: Record<string, any>) => {
    const {
      entityString,
      schemaString,
      selectedPlatform,
      selectedComponent,
      selectedEditorDisplayMode,
    } = this.state
    const jsonProps = { entityString, schemaString }
    // preserve any url override
    const { port, serverUrl } = queryString.parse(location.search)
    const additionalQueryParams = {
      port,
      serverUrl,
      selectedPlatform,
      selectedComponent,
      selectedEditorDisplayMode,
      ...additionalParams,
    }
    const url = getShareableToolsURL(jsonProps, additionalQueryParams)

    browserHistory.replace({
      search: url.search,
    })
  }
}

function ExpandableType({ typeName }) {
  const [expanded, setExpanded] = React.useState(false)
  return (
    <strong className="pointer" onClick={() => setExpanded(!expanded)}>
      {expanded ? typeName : typeName.substring(0, 25) + '...'}
    </strong>
  )
}

const ErrorMessageContainer = styled.div`
  background-color: #ffebee;
  border-radius: 5px;
  justify-content: center;
  margin-top: 5px;
`

const ErrorTitle = styled.div`
  font-weight: bold;
  color: #d45c43;
  padding-right: 5px;
  padding-left: 5px;
`

const ErrorText = styled.div`
  color: #d45c43;
  padding-right: 5px;
  padding-left: 10px;
`
