import _ from 'lodash'
import { IFormulaLocalContext } from '../../formulas'
import { MemoCacheKey } from '../../types'

export function negate(value: any) {
  return _.negate(value)
}

export function memoize(func: any, deps?: any[]) {
  const localContext = this as IFormulaLocalContext
  const { componentInfo } = localContext
  const frames = componentInfo?.frames
  const renderer = frames?.getContext('renderer')

  const cacheKey = stringifyDeps(func, deps, componentInfo?.id)
  const memoizedFormula = renderer?.getMemoizedFormula(cacheKey)
  if (memoizedFormula) {
    return memoizedFormula
  }

  // if not exist yet, create a new memoize formula and store in local scope
  const memoizableFormula = _.memoize(func)
  renderer?.setMemoizedFormula(cacheKey, memoizableFormula)

  return memoizableFormula
}

function stringifyDeps(
  func: Function,
  deps: any[] | undefined,
  prefix: string | undefined
): MemoCacheKey {
  if (_.isEmpty(deps)) {
    return prefix ?? func
  }

  const mapped = deps.map(stringifyDep)
  if (prefix) {
    mapped.unshift(prefix)
  }
  return mapped.join('|')
}

function stringifyDep(dep: any): string {
  if (typeof dep === 'object') {
    return `obj:${getObjectRef(dep)}`
  } else if (typeof dep === 'function') {
    return `func:${getObjectRef(dep)}`
  } else {
    return `val:${String(dep)}`
  }
}

/* associate memoize's object dependencies with a random id */
const memoObjectRefMap = new WeakMap<object, string>()

function getObjectRef(obj: object): string {
  if (!memoObjectRefMap.has(obj)) {
    memoObjectRefMap.set(obj, Math.random().toString(36).substring(2))
  }
  return memoObjectRefMap.get(obj)
}

const cacheUntilMap = new WeakMap<object, Map<any, Function>>()

export function cacheUntil(func: any, deps?: any[]) {
  const localContext = this as IFormulaLocalContext
  const { componentInfo } = localContext
  const frames = componentInfo?.frames
  const renderer = frames?.getContext('renderer')

  if (!renderer) {
    throw new Error('CACHE_UNTIL: not currently supported outside of a renderer context')
  }

  // check cache for function
  if (!cacheUntilMap.has(renderer)) {
    cacheUntilMap.set(renderer, new Map())
  }
  const cache = cacheUntilMap.get(renderer)
  if (!cache.has(componentInfo?.id)) {
    // will be captured in a closure so the function can re-use
    let lastDependencies: any[] = []
    let lastResult

    // create a new function to cache
    const newFunc = (func, deps) => {
      const dependenciesChanged =
        lastDependencies?.length !== deps?.length ||
        deps?.some((dep, index) => dep !== lastDependencies[index])

      if (dependenciesChanged) {
        const newResult = func()
        lastResult = newResult
        lastDependencies = deps
      }

      return lastResult
    }
    cache.set(componentInfo?.id, newFunc)
  }

  const cachedFunc = cache.get(componentInfo?.id)
  return cachedFunc(func, deps)
}
