import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Select, { InputActionMeta } from 'react-select'
import Creatable from 'react-select/creatable'
import { defaultProps, ISelectProps, ReactSelectProps, SelectStyleProps } from './interface'
import { createFilterFromAdditionalFields } from '../filters'

import 'browser/components/atomic-elements/atoms/select/select/_react-select.scss'
import { translateString } from 'shared-libs/helpers/utils'

function getterFromPath(path: string) {
  if (!path) {
    return undefined;
  }
  return (obj: any) => _.get(obj, path, undefined)
}

export class ReactSelect extends React.Component<ISelectProps, any> {
  public static defaultProps = defaultProps

  private inputValue: string
  private select: Select
  private valueCache: any
  private _prevOptions: any[]
  private _prevOptionsBackup: any[]

  constructor(props) {
    super(props)
    this.valueCache = {}
  }

  public focus() {
    this.select.focus()
  }

  public render() {
    const {
      autoFocus,
      inputClassName,
      size,
      errorText,
      isCreatable,
      isDisabled,
      isEditableInline,
      isHorizontalLayout,
      optionLabelPath,
      optionValuePath,
      isStatic,
      multi,
      valueRenderer,
      optionRenderer,
      formatOptionLabel,
      onOpen,
      filterByFieldPaths,
      noOptionsMessage
    } = this.props
    const noResultsText = this.getNoResultsText()
    const sizeClassName = _.isEmpty(size) ? '' : `c-select--${size}`
    const className = classNames('c-select', sizeClassName, inputClassName, {
      'c-select--error': !_.isEmpty(errorText),
      'c-select--isDisabled': isDisabled,
      'c-select--isEditableInline': isEditableInline,
      'c-select--isHorizontalLayout': isHorizontalLayout,
      'c-select--isStatic': isStatic,
      'c-select--isVerticalLayout': !isHorizontalLayout,
    })
    const options = this.getOptions()
    const menuPortalTarget = document.getElementsByTagName('body')[0]

    const selectProps: ReactSelectProps = {
      ...this.props,
      autofocus: autoFocus,
      className,
      disabled: isDisabled || isStatic,
      labelKey: optionLabelPath,
      getOptionLabel: getterFromPath(optionLabelPath),
      noResultsText,
      onChange: this.handleChange,
      onClose: this.handleClose,
      onInputChange: this.handleInputChange,
      options,
      ref: this.handleRef,
      tabIndex: isDisabled ? '-1' : '0',
      valueKey: optionValuePath,
      getOptionValue: getterFromPath(optionValuePath),
      // value: this.getValue(options),
      styles: this.buildStyles(),
      classNamePrefix: 'reactSelect',
      components: {},
      isMulti: multi,
      menuPlacement: 'auto',
      menuPortalTarget,
      value: this.getValue(options),
      onMenuOpen: onOpen,
      noOptionsMessage: noOptionsMessage ? (_) => { return noOptionsMessage } : undefined
    }
    if (valueRenderer) {
      selectProps.components.MultiValue = valueRenderer
      selectProps.components.SingleValue = valueRenderer
    }
    if (optionRenderer) {
      selectProps.components.Option = optionRenderer
    }
    if (filterByFieldPaths) {
      selectProps.filterOption = createFilterFromAdditionalFields(filterByFieldPaths)
    }
    if (formatOptionLabel) {
      selectProps.formatOptionLabel = formatOptionLabel
    }
    if (isCreatable) {
      return <Creatable {...selectProps} />
    } else {
      return <Select {...selectProps} />
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Private Functions
  //////////////////////////////////////////////////////////////////////////////

  private getValue(options) {
    const { multi, value } = this.props
    if (!value) {
      return value
    }
    if (multi) {
      return _.map(value, (val) => this.getValueFromEntry(val, options))
    } else {
      return this.getValueFromEntry(value, options)
    }
  }

  private getValueFromEntry(value, options) {
    const { optionValuePath } = this.props

    // backwards compatability, some components pass in the full value
    if (_.isObject(value)) {
      return value
    }

    // progressive fallbacks
    return this.valueCache[value]
      || _.find(options, (option) => _.get(option, optionValuePath) === value)
      || this.buildValueFromId(value)
  }

  private buildValueFromId(id: string) {
    const { optionValuePath, optionLabelPath } = this.props
    const value = {}
    _.set(value, optionValuePath, id)
    _.set(value, optionLabelPath, id)
    return value
  }

  private getOptions() {
    let options = this._getOptionsHelper()
    const inputValue = this.inputValue

    const {
      showRemoveOption,
      removeOptionText,
      optionLabelPath,
      optionValuePath,
      showCreatableWithResults,
      isAsync,
      isCreatable,
    } = this.props

    if (isAsync && ((isCreatable && _.isEmpty(options)) || showCreatableWithResults) &&  !_.isEmpty(inputValue)) {
      const createOptions = this.translate([
        {
          _isCreatableOption: true,
          _label: inputValue,
          className: 'Select-create-option-placeholder',
          [optionValuePath]: inputValue,
          [optionLabelPath]: `Create "${inputValue}"`,
        },
      ])

      options = createOptions.concat(options)
    }

    if (!_.isEmpty(this.getValue(options)) && showRemoveOption) {
      const removeOption = {
        [optionLabelPath]: removeOptionText || 'Remove Value',
        [optionValuePath]: null,
      }
      options = [removeOption].concat(options)
    }

    return options
  }

  private _getOptionsHelper() {
    const {
      multi,
      options,
      optionMapper,
    } = this.props

    if (_.isEmpty(options)) {
      return []
    } else if (multi) {
      // TODO: investigate if optionMapper should be applied when multi is enabled
      return this.translate(options)
    } else if (optionMapper) {
      // Functionality for the Creatable select field relies on modifying the options list
      // If we blow it away by creating a new options map on every render call, it doesn't work
      const computedOptions = optionMapper(options)

      if (_.isEqual(this._prevOptionsBackup, computedOptions)) {
        return this._prevOptions
      }

      this._prevOptions = computedOptions
      this._prevOptionsBackup = _.clone(this._prevOptions)

      return this.translate(computedOptions)
    }
    return this.translate(options)
  }

  private translate(options) {
    const { optionLabelPath, frames } = this.props
    const translationTable = frames?.getContext('translationTable')
    return _.map(options, (opt) => {
      const translatedLabel = translateString(_.get(opt, optionLabelPath), translationTable)
      const translatedOpt = _.clone(opt)
      return _.set(translatedOpt, optionLabelPath, translatedLabel)
    })
  }

  private getNoResultsText() {
    const {
      isAsync,
      isLoading,
      loadingPlaceholder,
      noResultsText,
      options,
      searchPromptText,
    } = this.props
    const inputValue = this.inputValue
    if (!isAsync) {
      return noResultsText
    }
    // return NoResultsText for Async select. react select has some internal logic
    // that display no results text when the internal filter returns empty even if
    // there are options from the server. This logic is unreliable but somehow
    // get works around that
    if (!_.isEmpty(options)) {
      return ''
    }
    if (_.isEmpty(inputValue)) {
      return searchPromptText
    }
    if (isLoading) {
      return loadingPlaceholder
    }
    return noResultsText
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleChange = (option) => {
    const { isCreatable, multi, onNewOptionClick, onChange, optionValuePath } = this.props
    // if option is cleared
    if (_.isNil(option)) {
      onChange(null, null)
      return
    }
    // handle if on create option is clicked
    if (isCreatable && option._isCreatableOption) {
      onNewOptionClick(option._label)
      return
    }
    let value = option
    if (optionValuePath) {
      if (multi) {
        value = this.props.value || option

        // support mutability by writing values from the `option` array into the
        // existing `value` array. This enables scenarios where a formula field
        // field can write an array reference to the same path as the select,
        // and the select then modifies it directly. Otherwise, the formula
        // field just overwrites whatever the new array coming from react-select
        // here, so the changes don't stick.

        // (not 100% sure if `option` is always non-nil and an array here,
        // erring on safe side)
        if (_.isArray(value)) {
          value.splice(0, value.length, ..._.map(option, optionValuePath))
        }
      } else {
        value = _.get(option, optionValuePath)
      }
    }

    // keep a local copy of the selected value(s)
    if (multi) {
      _.forEach(option, (opt) => {
        _.set(this.valueCache, _.get(opt, optionValuePath), opt)
      })
    } else {
      _.set(this.valueCache, value, option)
    }

    onChange(value, option)
  }

  private handleClose = () => {
    this.handleInputChange('')
  }

  private handleInputChange = (input, actionMeta?: InputActionMeta) => {
    // **********
    // DEBUG - if you need to inspect a react select, put a breakpoint here to prevent it from closing on blur
    // **********

    const { onInputChange } = this.props
    if (onInputChange) {
      onInputChange(input, actionMeta)
    }
    this.inputValue = input
  }

  private handleRef = (ref) => {
    const { onSelectRef } = this.props
    this.select = ref
    if (onSelectRef) {
      onSelectRef(ref)
    }
  }

  private buildStyles = () => {
    const { styles, validator } = this.props
    const allStyles: SelectStyleProps = {
      ...(styles || {}),
      menu: (base) => ({
        ...base,
        zIndex: 105,
        minWidth: '300px',
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 950
      }),
      control: (base) => ({
        ...base,
        borderWidth: 0,
        minHeight: '32px',
        outline: 'none',
        boxShadow: 'none',
        marginBottom: '1px',
      }),
      valueContainer: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
      }),
      dropdownIndicator: (base) => ({
        ...base,
        padding: '6px'
      }),
      multiValue: (base, { data }) => {
        const valueStyles = { ...base }
        if (validator && !validator(data)) {
          valueStyles.backgroundColor = '#f4afae'
          valueStyles.borderColor = '#e53935'
        }
        return valueStyles
      },
    }
    return _.mapValues(allStyles, (styleFunc, propertyName) => {
      if (styles && styles[propertyName]) {
        return (base, props) => {
          const defaultStyles = styleFunc(base, props)
          return styles[propertyName](defaultStyles, props)
        }
      } else {
        return styleFunc
      }
    })
  }
}
