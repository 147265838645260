import _ from 'lodash'

export class DefaultSetter {
  private jsonSchemaResolver: any
  private resolveEntitySchema: (schema: any) => any

  constructor(jsonSchemaResolver, resolveEntitySchema) {
    this.jsonSchemaResolver = jsonSchemaResolver
    this.resolveEntitySchema = resolveEntitySchema
  }

  public apply(schema, instance) {
    this.applyDefaults({
      context: schema,
      schema,
      instance,
    })
  }

  private applyArrayDefaults(props) {
    const { context, schema, instance } = props
    _.forEach(instance, (item) => {
      // TODO(Peter): schema.items might not exist because we are overriding
      // some props e.g. BillOfLading.json overriding document.attachment
      if (!schema.items) { return }
      this.applyDefaults({
        context,
        schema: schema.items,
        instance: item
      })
    })
  }

  private applyObjectDefaults(props) {
    const { context, schema, instance } = props
    _.forEach(schema.properties, (property, key) => {
      const resolvedProp = this.resolveSchema(context, property).schema
      // if property value isNil and default is available, apply it
      if (_.isNil(instance?.[key]) && !_.isNil(resolvedProp.default)) {
        instance[key] = _.cloneDeep(resolvedProp.default)
      } else if (resolvedProp.type === 'array' && resolvedProp.minItems > 0) {
        // This logic is not correct. Sometimes we don't want to insert empty items
        // if (!_.isArray(instance[key])) {
        //   instance[key] = []
        // }
        // while (instance[key].length < resolvedProp.minItems) {
        //   instance[key].push({})
        // }
      }
      // if property isNil at this point, don't traverse
      if (_.isNil(instance?.[key])) { return }
      this.applyDefaults({
        context,
        schema: property,
        instance: instance[key]
      })
    })
  }

  private applyDefaults = ({ context, schema, instance }) => {
    const resolved = this.resolveSchema(context, schema)
    context = resolved.context
    schema = resolved.schema
    const type = schema.type
    const props = { context, schema, instance }
    if (type === 'array') {
      this.applyArrayDefaults(props)
    } else if (type === 'object') {
      this.applyObjectDefaults(props)
    }
  }

  private resolveSchema(context, schema) {
    let resolved = this.jsonSchemaResolver.resolveSubschema(context, schema)
    if (!resolved) {
      resolved = this.resolveEntitySchema(schema)
    }
    return resolved
  }
}
